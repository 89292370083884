import { TrafficSourceType, TrafficSource } from "types";
import environment from "../../environment";
import { getCategoryById } from "../../components/Content/Product/useCategory";

const KEY_NAME = `TRFS_${environment.name}`;
const TTL = 48 * 60 * 60 * 1000;

class TrafficSourceClass {
  constructor() {}

  updateLandingpage(url: string) {
    if (!this.getWithExpiry(KEY_NAME)) {
      const type: TrafficSourceType = this.getTrafficSourceType(url);
      const gclid = this.getGclid(url);
      const trafficSource: TrafficSource = {
        type,
        gclid,
      };
      this.setWithExpiry(KEY_NAME, trafficSource, TTL);
    }
  }

  getTrafficSourceType(url: string): TrafficSourceType {
    const referrer = this.getReferrer();
    const referrerDomain = this.getUrlInfos(referrer).hostname;
    const referrerSearch = this.getUrlInfos(referrer).search;
    const urlPath = this.getUrlInfos(url).pathname;
    const urlPathParts = urlPath ? urlPath.replace(/^\/|\/$/, "").split("/") : [];
    const pageName = urlPathParts.length >= 2 ? urlPathParts[1] : null;
    const pageSubName = urlPathParts.length >= 3 ? urlPathParts[2] : "unknown";
    const pagePath = urlPathParts.join("-");
    const isInternalCategory = pageName && !!getCategoryById(pageName);
    const isExternalCategory = pageName == "p";
    const isProduct = urlPathParts.length == 3 && (isInternalCategory || isExternalCategory);

    if (referrerDomain?.includes("facebook") || referrerSearch?.includes("fbclid=")) {
      return `social-facebook-${pagePath}`;
    }

    if (referrerDomain?.includes("instagram")) {
      return `social-instagram-${pagePath}`;
    }

    if (referrerDomain?.includes("pinterest")) {
      return `social-pinterest-${pagePath}`;
    }

    if (referrerSearch?.includes("gclid=")) {
      return `display-google-${pagePath}`;
    }

    if (referrerDomain?.includes("google")) {
      if (pageName == "blog") {
        return `organic-google-blog-${pageSubName}`;
      }

      if (isProduct) {
        return `organic-google-product-${pagePath}`;
      }

      if (isInternalCategory || isExternalCategory) {
        return `organic-google-category-${pageName}`;
      }

      if (pageName == "beste") {
        return `organic-google-best-${pageSubName}`;
      }

      if (pageName == "kaufberatung-fahrradgroesse") {
        return "organic-google-tool-bikesize";
      }

      if (pageName == "kaufberatung-fahrradgroesse") {
        return "organic-google-tool-bikesize";
      }

      if (pageName == "rechner") {
        if (urlPathParts.length >= 3) {
          return `organic-google-tool-calculators-${pageSubName}`;
        } else {
          return "organic-google-tool-calculators";
        }
      }

      if (pageName == "marken") {
        if (urlPathParts.length >= 3) {
          return `organic-google-brands-${pageSubName}`;
        } else {
          return "organic-google-brands";
        }
      }

      if (pageName == "ebike-vergleich" || pageName == "fahrrad-vergleich") {
        return "organic-google-tool-bikecompare";
      }

      if (pageName == "welches-ebike-passt-zu-mir" || pageName == "welches-fahrrad-passt-zu-mir") {
        return "organic-google-tool-bikefinder";
      }

      return `organic-google-other-${pageName}`;
    }

    if (!referrer) {
      return `direct-${pagePath}`;
    }

    return `other-${pagePath}`;
  }

  getTrafficSource(): TrafficSource | null {
    return this.getWithExpiry(KEY_NAME);
  }

  getGclid(url: string): string | null {
    const searchParams = this.getUrlInfos(url).search;
    const reg = new RegExp("[?&]gclid=([^&#]*)", "i");
    const matches = reg.exec(searchParams);
    if (matches && matches.length > 0) {
      const gclid = matches[1];
      return gclid;
    }
    return null;
  }

  getReferrer(): string | null {
    if (typeof window !== "undefined") {
      return document.referrer;
    }
    return null;
  }

  getUrlInfos(url) {
    if (url && typeof window !== "undefined") {
      const linkElement = document.createElement("a");
      linkElement.href = url;
      return {
        hostname: linkElement.hostname,
        pathname: linkElement.pathname,
        search: linkElement.search,
      };
    }
    return {
      hostname: null,
      pathname: null,
      search: null,
    };
  }

  setWithExpiry(key, value, ttl) {
    if (typeof window !== "undefined") {
      const now = new Date();
      const item = {
        value: value,
        expiry: now.getTime() + ttl,
      };
      localStorage.setItem(key, JSON.stringify(item));
    }
  }

  getWithExpiry(key) {
    if (typeof window !== "undefined") {
      const itemStr = localStorage.getItem(key);
      if (!itemStr) {
        return null;
      }
      const item = JSON.parse(itemStr);
      const now = new Date();
      if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null;
      }
      return item.value;
    }
    return null;
  }
}

const TrafficSource = new TrafficSourceClass();
export default TrafficSource;
