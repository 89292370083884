import Cookies from "universal-cookie";

const PREFIX = "AMPLCOMP_";
const BIKELIST = "BIKES";

export class BikeCompareStorage {
  static cks = null;
  static callbacks = {};
  static state = {};

  static getCookies() {
    if (!BikeCompareStorage.cks) BikeCompareStorage.cks = new Cookies();
    return BikeCompareStorage.cks;
  }

  static from(request) {
    if (request) {
      BikeCompareStorage.cks = new Cookies(request);
    }
  }

  static reset() {
    BikeCompareStorage.state = {};
    BikeCompareStorage.cks = new Cookies();
  }

  static setItem(flavor, key, value) {
    BikeCompareStorage.state[`${flavor}_${key}`] = value;
    try {
      const cookies = BikeCompareStorage.getCookies();
      cookies.set(`${PREFIX}_${flavor}_${key}`, value, {
        path: "/",
        maxAge: 60 * 60 * 24 * 365,
        secure: false,
        httpOnly: false,
        sameSite: "strict",
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn("Cannot use cookies for bike compare");
    }
    const bikes = key == BIKELIST ? value.split(",") : BikeCompareStorage.getBikes(flavor);
    if (BikeCompareStorage.callbacks[flavor]) {
      BikeCompareStorage.callbacks[flavor].forEach((callback) => callback(bikes));
    }
    return value;
  }

  static getItem(flavor, key) {
    try {
      const cookies = BikeCompareStorage.getCookies();
      const value = cookies.get(`${PREFIX}_${flavor}_${key}`);
      return value;
    } catch (e) {
      return BikeCompareStorage.state[`${flavor}_${key}`];
    }
  }

  static addBike(flavor, seoId) {
    const bikes = BikeCompareStorage.getItem(flavor, BIKELIST);
    if (bikes) {
      const ids = [...new Set([...bikes.split(","), seoId])];
      BikeCompareStorage.setItem(flavor, BIKELIST, ids.join(","));
    } else {
      BikeCompareStorage.setItem(flavor, BIKELIST, seoId);
    }
  }

  static removeBike(flavor, seoId) {
    const bikes = BikeCompareStorage.getBikes(flavor);
    const newList = bikes.filter((b) => b != seoId);
    BikeCompareStorage.setItem(flavor, BIKELIST, newList.join(","));
  }

  static resetBikes(flavor, bikes) {
    BikeCompareStorage.setItem(flavor, BIKELIST, bikes.join(","));
  }

  static getBikes(flavor) {
    const bikes = BikeCompareStorage.getItem(flavor, BIKELIST);
    if (bikes) {
      return bikes.split(",");
    } else {
      return [];
    }
  }

  static isAdded(flavor, seoId) {
    const bikes = BikeCompareStorage.getBikes(flavor);
    return bikes.indexOf(seoId) >= 0;
  }

  static onChange(flavor, callback) {
    BikeCompareStorage.callbacks[flavor] = BikeCompareStorage.callbacks[flavor]
      ? [...BikeCompareStorage.callbacks[flavor], callback]
      : [callback];
    return () => {
      const index = BikeCompareStorage.callbacks[flavor].indexOf(callback);
      if (index > -1) {
        BikeCompareStorage.callbacks[flavor].splice(index, 1);
      }
    };
  }
}
