module.exports = {
  locales: ["de", "en"],
  defaultLocale: "de",
  pages: {
    "*": [
      "common",
      "categories",
      "footer",
      "navmenu",
      "categoryHighlights",
      "newsletter",
      "productDetails",
      "bikeDetails",
      "frequentlyAskedQuestions",
      "categoryUseCases",
      "priceAlert",
      "bikeDetails",
    ],
    "/": ["descriptionProduct"],
    "rgx:/beste/*": ["bestBikesCommons", "bestBikesBioBikes", "bestBikesEbikes"],
    "/kaufberatung-fahrradgroesse": ["bikeSize"],
    "/partner": ["partner"],
    "/rechner/kettenlaenge-berechnen": ["chainLength"],
    "/welches-ebike-passt-zu-mir": ["ebikeFinder"],
    "/welches-fahrrad-passt-zu-mir": ["bikeFinder"],
    "/about": ["about"],
    "/blogger": ["blogger"],
    "/marken": ["marken"],
    "/rechner/psi-bar-rechner": ["psiBar"],
    "/marken/[generic]": ["marken"],
    "/online-kaufberatung": ["onlineKaufberatung"],
    "/rechner/reifenumfang-rechner": ["radumfang"],
    "/rechner/lumen-lux-rechner": ["lumenLuxRechner"],
    "/rechner/fahrrad-restwert-rechner": ["bicycleResidualValue"],
    "/rechner/kalorienverbrauch-rechner": ["kalorienverbrauchRechner"],
    "/rechner/fahrrad-reifendruck-rechner": ["bikeTirePressure"],
    "/rechner/fahrrad-wattrechner": ["bicycleWattCalculator"],
    "/rechner/fahrrad-speichenrechner": ["spokeCalculator"],
    "/rechner/ausreisser-rechner": ["ausreisserRechner"],
    "/rechner/fahrrad-pace-rechner": ["paceRechner"],
    "/rechner/e-bike-reichweitenrechner": ["eBikeReichweitenRechner"],
    "/rechner/fahrrad-ritzelrechner": ["ritzelRechner"],
    "/rechner/lebenserwartungsrechner": ["lebenserwartungsrechner"],
    "/rechner/geschwindigkeitsrechner": ["geschwindigkeitRechner"],
    "/rechner/trainingspuls-maximalpuls-rechner": ["pulseRechner"],
    "/rechner/fahrrad-vs-auto-rechner": ["fahrradVvsAutoRechner"],
    "/rechner/vo2max-rechner": ["vo2maxRechner"],
    "/rechner/fahrrad-e-bike-versicherung-vergleichsrechner": ["fahrradEbikeVersicherungVergleichsrechner"],
    "/kinderfahrrad-finder": ["kinderfahrradFinder"],
    "/woom-bike-groesse": ["woomBikeGroesse"],
  },
};
