import { AuthStorage } from "../../lib/authStorage";
import TrafficSource from "src/lib/analytics/trafficSource";
// import ReactPixel from "react-facebook-pixel";
import { createFetcher } from "src/lib/fetcher";
import environment from "../../environment";
import nanoid from "nanoid";
import { sendAnalyticsBatch } from "../../graphql/mutations";

const pageviewKey = "pageview";

export type EventListenerFunction = (eventName: string, eventData: any) => void;

class AnalyticsClass {
  env: any;
  sessionId: any;
  userId: any;
  queueMessages: boolean;
  initPromise: Promise<unknown>;
  initPromiseResolve: (value: unknown) => void;
  fbPixelPromise: Promise<unknown>;
  eventQueue: any[];
  sendQueue: any[];
  firstPageViewSent: boolean;
  fbPixelPromiseResolve: (value: unknown) => void;
  eventListeners: { [id: string]: Array<EventListenerFunction> } = {};
  productSearchListener?: () => void;
  searchbarListener?: () => void;

  constructor() {
    this.env = environment.name;
    this.sessionId = nanoid(5);
    this.userId = AuthStorage.getUserId()
      ? AuthStorage.getUserId()
          .split(":")
          .pop()
      : null; // remove cognito to get rid of this
    this.queueMessages = true;
    this.initPromise = new Promise((resolve, reject) => {
      this.initPromiseResolve = resolve;
    });
    this.fbPixelPromise = new Promise((resolve, reject) => {
      this.fbPixelPromiseResolve = resolve;
    });
    this.eventQueue = [];
    this.sendQueue = [];
    this.firstPageViewSent = false;
    this.startEventHandlerLoop();
    this.startBatchSendLoop();
  }

  async sendAnalytics(eventName, eventData) {
    this.eventQueue.push({ name: eventName, data: eventData });
    const listeners: Array<EventListenerFunction> | undefined = this.eventListeners[eventName];
    listeners?.forEach((fn) => fn(eventName, eventData));
  }

  listenFor(eventNames: string[], fn: EventListenerFunction) {
    for (const eventName of eventNames) {
      if (!this.eventListeners[eventName]) {
        this.eventListeners[eventName] = [];
      }
      this.eventListeners[eventName].push(fn);
    }
    return () => {
      for (const eventName of eventNames) {
        let index = this.eventListeners[eventName].indexOf(fn);
        if (index !== -1) {
          this.eventListeners[eventName].splice(index, 1);
        }
      }
    };
  }

  async startBatchSendLoop() {
    await this.initPromise;
    const fetcher = createFetcher();
    const intervalMS = 500;
    const maxBatchSize = 20;
    const send = async (events: any[]) => {
      if (this.env == "dev") {
        // eslint-disable-next-line no-console
        console.log("Skipping analytics in dev: " + JSON.stringify(events));
      } else {
        await fetcher([sendAnalyticsBatch, JSON.stringify({ events })]);
      }
    };
    setInterval(async () => {
      const events = this.sendQueue.splice(0, maxBatchSize);
      if (events.length > 0) {
        await send(events);
      }
    }, intervalMS);
  }

  async startEventHandlerLoop() {
    await this.initPromise;
    const intervalMS = 50;
    const send = async (name, data) => {
      if (!this.userId) {
        return;
      }
      const trafficSource = TrafficSource.getTrafficSource();

      const payload = {
        ...(data ? data : {}),
        lastPage: window?.location?.href,
        trafficSourceType: trafficSource?.type,
      };

      const variables = {
        env: this.env,
        userId: this.userId,
        sessionId: this.sessionId,
        eventName: name,
        dataJson: JSON.stringify(payload),
        fbc: undefined,
        documentReferrer: undefined,
      };
      if (window?.location?.href) {
        const fbclid = new URL(window.location.href).searchParams?.get("fbclid");
        if (fbclid) {
          variables.fbc = `fb.1.${new Date().getTime()}.${fbclid}`;
        }
      }
      if (document.referrer) {
        variables.documentReferrer = document.referrer;
      }

      this.sendQueue.push(variables);

      this.sendGtagEvent(name, payload);
    };

    setInterval(async () => {
      //ensure pageview is sent as first event to not screw bounce rate
      if (!this.firstPageViewSent) {
        for (var i = 0; i < this.eventQueue.length; i++) {
          const { name, data } = this.eventQueue[i];
          if (name == pageviewKey) {
            this.eventQueue.splice(i, 1);
            await send(name, data);
            this.firstPageViewSent = true;
            break;
          }
        }
      } else {
        while (this.eventQueue.length > 0) {
          const { name, data } = this.eventQueue.pop();
          await send(name, data);
        }
      }
    }, intervalMS);
  }

  async init(userId) {
    this.userId = userId.split(":").pop();
    this.sessionId = userId.slice(-5);

    if (typeof window === "undefined") {
      // don't do analytics in server
      return;
    }

    this.initPromiseResolve("ok");
  }

  async getUserId() {
    await this.initPromise;
    return this.userId;
  }

  async pageView(url, title) {
    const urlFull = url;
    const urlWithoutQueryParameters = urlFull.split(/[\?#]+/)[0];
    this.sendAnalytics(pageviewKey, {
      url: urlWithoutQueryParameters,
      urlFull,
      title,
    });
  }

  async adClick(id, attributes) {
    this.sendAnalytics("adClick", {
      productId: id,
      category: "Ad",
      ...attributes,
    });
  }

  async adImpression(id, attributes) {
    this.sendAnalytics("adImpression", {
      productId: id,
      category: "Ad",
      ...attributes,
    });
  }

  async productDetailsImpression(id, seoId, currency, value, name, brand, category) {
    this.sendAnalytics("view_item", {
      productId: id,
      productName: name,
      productSeoId: seoId,
      currency,
      value,
      category: "Product",
      items: [
        {
          item_id: id,
          item_name: name,
          item_brand: brand,
          item_category: category,
        },
      ],
    });
  }

  async productClick(id, seoId, name, attributes) {
    this.sendAnalytics("productClick", {
      productId: id,
      productName: name,
      productSeoId: seoId,
      category: "Product",
      ...attributes,
    });
  }

  async productForward(id, seoId, name, attributes) {
    this.sendAnalytics("productForward", {
      productId: id,
      productName: name,
      productSeoId: seoId,
      category: "Product",
      ...attributes,
    });
  }

  async productLike(id, attributes) {
    this.sendAnalytics("productLike", {
      productId: id,
      category: "Product",
      ...attributes,
    });
  }

  async articleClick(id, attributes) {
    this.sendAnalytics("articleClick", {
      articleId: id,
      category: "Article",
      ...attributes,
    });
  }

  async menuNavigate(path) {
    this.sendAnalytics("menuNavigate", {
      path,
      category: "Menu",
    });
  }

  async menuOpen() {
    this.sendAnalytics("menuOpen", {
      category: "Menu",
    });
  }

  async categoryHighlightClick(category) {
    this.sendAnalytics("categoryHighlightClick", {
      category,
    });
  }

  async productFilter(category) {
    this.sendAnalytics("productFilter", {
      category,
    });
  }

  clearProductSearchListerer(): boolean {
    if (this.productSearchListener) {
      this.productSearchListener();
      this.productSearchListener = null;
      return true;
    }
    return false;
  }

  clearSearchbarListerer(): boolean {
    if (this.searchbarListener) {
      this.searchbarListener();
      this.searchbarListener = null;
      return true;
    }
    return false;
  }

  async productSearchClickMiss(searchTerm, productCount) {
    this.sendAnalytics("productSearchClickMiss", {
      searchTerm,
      productCount,
      category: "Product",
    });
  }

  async productSearchClickHit(searchTerm, productCount) {
    this.sendAnalytics("productSearchClickHit", {
      searchTerm,
      productCount,
      category: "Product",
    });
  }

  async productSearch(searchTerm, productCount) {
    this.sendAnalytics("productSearch", {
      searchTerm,
      productCount,
      category: "Product",
    });
    if (this.clearProductSearchListerer()) {
      this.productSearchClickMiss(searchTerm, productCount);
    }
    this.productSearchListener = this.listenFor(["productClick"], (name, data) => {
      this.productSearchClickHit(searchTerm, productCount);
      this.clearProductSearchListerer();
    });
  }

  async productSearchFocus() {
    this.sendAnalytics("productSearchFocus", {
      category: "Product",
    });
  }

  async productSearchNoResults(searchTerm) {
    this.sendAnalytics("productSearchNoResults", {
      searchTerm,
      category: "Product",
    });
    if (this.clearProductSearchListerer()) {
      this.productSearchClickMiss(searchTerm, 0);
    }
  }

  async searchbarClickMiss(searchTerm, productCount, articleCount, termCount) {
    this.sendAnalytics("searchbarClickMiss", {
      searchTerm,
      productCount,
      articleCount,
      termCount,
      category: "Product",
    });
    this.clearSearchbarListerer();
  }

  async searchbarClickHit(
    searchTerm,
    clickTarget: "product" | "article" | "term" | "unknown",
    productCount,
    articleCount,
    termCount
  ) {
    this.sendAnalytics("searchbarClickHit", {
      searchTerm,
      productCount,
      articleCount,
      termCount,
      clickTarget,
      category: "Product",
    });
    this.clearSearchbarListerer();
  }

  async searchbarSearch(searchTerm, productCount, articleCount, termCount) {
    this.sendAnalytics("searchbarSearch", {
      searchTerm,
      productCount,
      articleCount,
      termCount,
      category: "Searchbar",
    });
    this.clearSearchbarListerer();

    this.searchbarListener = this.listenFor(["articleClick", "productClick"], (name, data) => {
      if (name == "productClick") {
        this.searchbarClickHit(searchTerm, "product", productCount, articleCount, termCount);
      } else if (name == "articleClick") {
        this.searchbarClickHit(searchTerm, "article", productCount, articleCount, termCount);
      }
      this.clearSearchbarListerer();
    });
  }

  async productLoadMore() {
    this.sendAnalytics("productLoadMore", {
      category: "Product",
    });
  }

  async newsletterSubscribe(page) {
    this.sendAnalytics(`newsletterSubscribeClick-${page}`, {
      category: "Newsletter",
    });
  }

  async newsletterCTA(page) {
    this.sendAnalytics(`newsletterCTA-${page}`, {
      category: "Newsletter",
    });
  }

  async botStart(name) {
    this.sendAnalytics(`botStartClick`, {
      category: "Bot",
      name,
    });
  }

  async cookieDecline() {
    this.sendAnalytics("cookieDecline", {
      category: "Privacy",
    });
  }

  async cookieAccept() {
    this.sendAnalytics("cookieAccept", {
      category: "Privacy",
    });
  }

  async accountConfirm() {
    this.sendAnalytics("accountConfirm", {
      category: "Account",
    });
  }

  async accountForgot() {
    this.sendAnalytics("accountForgot", {
      category: "Account",
    });
  }

  async accountReset() {
    this.sendAnalytics("accountReset", {
      category: "Account",
    });
  }

  async accountLogin() {
    this.sendAnalytics("accountLogin", {
      category: "Account",
    });
  }

  async accountLogout() {
    this.sendAnalytics("accountLogout", {
      category: "Account",
    });
  }

  async accountRegister() {
    this.sendAnalytics("accountRegister", {
      category: "Account",
    });
  }

  async headerHighlightClick(page, link) {
    this.sendAnalytics("headerHighlightClick", {
      page,
      link,
      category: "HeaderHighlight",
    });
  }

  async webVitals(name, value, id) {
    this.sendAnalytics("webVitals", {
      category: "WebVitals",
      name,
      id,
      value,
    });
  }

  async priceAlertRegistration(emailAddress, productId, productCategory, productPrice) {
    this.sendAnalytics("priceAlertRegistration", { emailAddress, productId, productCategory, productPrice });
  }

  async priceAlertConfirmation(id, attributes) {
    this.sendAnalytics("priceAlertConfirmation", {
      productId: id,
      category: "Product",
      ...attributes,
    });
  }

  async priceAlertRemoval(id, attributes) {
    this.sendAnalytics("priceAlertRemoval", {
      productId: id,
      category: "Product",
      ...attributes,
    });
  }

  async waitForGtag(): Promise<void> {
    return new Promise<void>((resolve) => {
      if (window && typeof window.gtag === "function") {
        resolve();
      } else {
        const intervalId = setInterval(() => {
          if (window && typeof window.gtag === "function") {
            clearInterval(intervalId);
            resolve();
          }
        }, 100);
      }
    });
  }

  async sendGtagEvent(name, payload) {
    await this.waitForGtag();
    if (name == pageviewKey) {
      window.gtag("event", "page_view", {
        page_path: location?.pathname + location?.search + location?.hash,
        page_search: location?.search,
        page_hash: location?.hash,
      });
    } else {
      window.gtag("event", name, {
        ...payload,
        event_category: payload.category || "Default",
        event_label: payload.label || "Default",
        value: payload.value || "",
      });
    }
  }
}

const Analytics = new AnalyticsClass();
export default Analytics;
