import React, { FC } from "react";

import Head from "next/head";
import environment from "../../environment";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";

type SeoHeadProps = {
  title?: string;
  description?: string;
  keywords?: string;
  author?: string;
  ogUrl?: string;
  ogType?: string;
  ogTitle?: string;
  ogDescription?: string;
  ogImage?: string;
  ogImageWidth?: number | string;
  ogImageHeight?: number | string;
  ogLocale?: string;
  schema?: string;
  schema2?: string;
  canonical?: string;
};

const ensureAbsoluteUrl = (url: string) => {
  if (url && url.startsWith("/")) {
    return `${environment.backend.base_url}${url}`;
  }
  return url;
};

const SeoHead: FC<SeoHeadProps> = (props) => {
  const { t, lang: locale } = useTranslation("common");
  const router = useRouter();
  const defaultImage = "https://thecycleverse.com/images/The-Cycleverse-OpenGraph.jpg";

  const title = props.title ? props.title : t("seoTitleDefault");
  const description = props.description;
  const keywords = props.keywords;
  const author = props.author;
  const ogUrl = props.ogUrl
    ? ensureAbsoluteUrl(props.ogUrl)
    : `https://thecycleverse.com/${locale}${router.asPath != "/" ? router.asPath : ""}`;
  const ogType = props.ogType ? props.ogType : "website";
  const ogTitle = props.ogTitle ? props.ogTitle : title;
  const ogDescription = props.ogDescription ? props.ogDescription : t("seoDescriptionDefault");
  const ogImage = props.ogImage ? ensureAbsoluteUrl(props.ogImage) : defaultImage;
  const ogImageWidth = ogImage == defaultImage ? 1200 : props.ogImageWidth;
  const ogImageHeight = ogImage == defaultImage ? 630 : props.ogImageHeight;
  const ogLocale = props.ogLocale ? props.ogLocale : locale;
  const schema = props.schema;
  const schema2 = props.schema2;
  const canonical = props.canonical;

  return (
    <Head>
      <title key="title">{title}</title>
      {description && <meta key="description" name="description" content={description} />}
      {keywords && <meta key="keywords" name="keywords" content={keywords} />}
      {author && <meta key="author" name="author" content={author} />}
      {/* <meta
        key="twitter:card"
        name="twitter:card"
        content={DEFAULTprops.SEO.twitter.cardType}
      /> */}
      {/* <meta
        key="twitter:site"
        name="twitter:site"
        content={DEFAULTprops.SEO.twitter.handle}
      /> */}
      <meta key="og:url" property="og:url" content={ogUrl} />
      <meta key="og:type" property="og:type" content={ogType} />
      <meta key="og:title" property="og:title" content={ogTitle} />
      <meta key="og:description" property="og:description" content={ogDescription} />
      <meta key="og:image" property="og:image" content={ogImage} />
      {ogImageWidth && <meta key="og:image:width" property="og:image:width" content={`${ogImageWidth}`} />}
      {ogImageHeight && <meta key="og:image:height" property="og:image:height" content={`${ogImageHeight}`} />}
      <meta key="og:locale" property="og:locale" content={ogLocale} />
      {schema && <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: schema }} />}
      {schema2 && <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: schema2 }} />}
      {canonical && <link key="canonical" rel="canonical" href={`${canonical}`} />}
      {props.children}
    </Head>
  );
};

export default SeoHead;
