import { Theme, createTheme } from "@material-ui/core/styles";

import { red } from "@material-ui/core/colors";

const customThemeOptions = {
  widths: {
    contentWidth: 600,
    calculatorWidth: 800,
  },
  defaultBorderRadius: "4px",
  paragraphMaxWidth: 800,
  gobalMaxContentWidth: 1500,
  scrollBarWidth: "calc(100vw - 100%)",
  hightlightColor: "#9C00A2",
  headlineBlueColor: "#009fe5",
  hightlightColorSecondary: "#FFFF00",
  hightlightColorTertiary: "#05B709",
  highlightFontFamily: "DM Serif Text Regular",
  unflashyColor: "#a0a09e",
  unflashyerColor: "#EDECEC",
  tableBorderColor: "#d8d8d8",
  disclaimerColor: "#333333",
  globalMarginDesktop: 40,
  globalMarginMobile: 12,
  menuGutters: 12,
  cardSpacing: 5,
  cardButtonSpacing: 5,
  highlightGradient:
    "linear-gradient(90deg, rgba(0,174,239,1) 0%, rgba(3,78,162,1) 15%, rgba(236,0,140,1) 33%, rgba(255,20,0,1) 50%, rgba(255,209,0,1) 68%, rgba(154,204,91,1) 85%, rgba(0,166,81,1) 100%)",
  highlightGradientBg: "rgb(0,174,239)",
  highlightButtonPadding: 20,
  saleColor: "#FF4200",
  cyclinghacksColor: "#00AEEF",
  linkColor: "#00AEEF",
  journeysColor: "#12A537",
  adviceColor: "#12A537",
  likeColor: "#00AEEF",
  filterColor: "#00AEEF",
  openButtonColor: "#00AEEF",
  searchBarArticleColor: "#00AEEF",
  openButtonColorFromBlack: "invert(56%) sepia(70%) saturate(3430%) hue-rotate(162deg) brightness(99%) contrast(101%)",
  productDetailGroupHeader: "#00AEEF",
  toggleButtonActive: "#00AEEF",
  positiveColor: "#00AEEF",
  positiveAlternateColor: "#FF4200",
  toggleButtonActiveUnflashy: "#a5def3",
  menuBarHeight: 80,
  searchBarHeight: 51,
  tableHeaderFgColor: "#fff",
  tableHeaderBgColor: "#3598db",
  tableHeaderBorderColor: "#1578db",
  dividerBgColor: "#bababa",
  tableBodyBgColor: "#fff",
  productPageHighlightBackgroundColor: "#f6e6f2",
  productPageTeaserHeadlineColor: "#fd4901",
  bikeCompareIconSize: 20,
  bikeCompareAddIconSize: 26,
  bikeCompareColor: "#AA009B",
  bikeCompareColorMenu: "#BD1A8C",
  darkOrangeColor: "#f94902",
  lightOrangeColor: "#e67d5b",
  buttonColor: "#fff",
  bisColor: "#000000",
  orangeTextColor: "#fc6a0e",
  purpleTextColor: "#c11dd5",
  greenTextColor: "#1fa71a",
  redTextColor: "#FF0000",
  dividerColor: "#bababa",
  buttonBackgroundColor: "#04B0E9",
  blueTextColor: "#04B0E9",
  amountColor: "#01a70f",
  searchBackgroundWhite: "white",
  faqBackgroundWhite: "white",
  calculatorOrangeBoldColor: "#fd4901",
  grayColor: "#8b8b8b",
  grayBorderColor: "#d5d4d4",
  whiteBrandLetterColor: "#fff",
  grayBrandLetterColor: "#767575",
  blackBrandLetterColor: "#000",
  brayBorderColor: "#a6a6a6",
  newsletterOrangeTitle: "#F94902",
  bikeQualityChartFontColor: "black",
  bikeQualityChartRatingIndicatorFontColor: "white",
  bikeValueForMoneyXAxisLabelFontColor: "#8b8b8b",
  bikeValueForMoneyGridLineColor: "#8b8b8b",
  bikeValueForMoneyDataPt: "#00A4EB",
  bikeValueForMoneyDataPtCurrent: "#f7663a",
  bikeValueForMoneyDataPtSameCat: "#b834ac",
  bikePreviewBrandNameFontColor: "#AA009B",
  bikePreviewDetailBackgroundColor: "#fff",
  alertColor: "#FF4200",
  newsletterFooter: "#f6e6f1",
  sliderDisabled: "gray",
  footerLinkOffet: 50,
  resultColor: "#AA009B",
  chartColorFG: "#9C00A2",
  chartColorBG: "#fffAff",
  sliderLabelColor: "rgba(0, 0, 0, 0.87)",
  chartFontFamily: "Roboto",

  searchDivider: {
    height: 1,
    // NOTE: this is the fallback for old browsers if the next one
    // doesn't make sense to them
    background: "rgb(0,174,239)",
    // eslint-disable-next-line no-dupe-keys
    // @ts-ignore
    background:
      "linear-gradient(90deg, rgba(0,174,239,1) 0%, rgba(3,78,162,1) 15%, rgba(236,0,140,1) 33%, rgba(255,20,0,1) 50%, rgba(255,209,0,1) 68%, rgba(154,204,91,1) 85%, rgba(0,166,81,1) 100%)",
  },
  searchDividerAlternate: {
    height: 1,
    background: "#a0a09e",
  },
  blackButtonTextColor: "#000",
  whitebuttonbackground: "#fff",
  lightBlueColor: "#00a5ec",
  pinkColor: "#bb0182",
  lightGreenColor: "#1fa095",
  greenColor: "#00b111",
  lightGreen: "#069f10",
  blogClasses: {
    blogContent: {
      maxWidth: "100%",
      margin: "0px",
      minHeight: "94vh",
    },
    filterBar: {
      position: "sticky",
      top: 0,
      zIndex: 1,
      "@media (max-width: 600px)": {
        top: 80,
      },
    },
    filterBarWithContent: {
      position: "sticky",
      top: 0,
      zIndex: 1,
    },
  },
  headline1: {
    fontFamily: "inherit",
    fontSize: "1.25rem",
    fontWeight: 900,
    marginBottom: 8,
  },
  headline2: {
    fontSize: 24,
    lineHeight: 1.1,
    marginBottom: 5,
  },
  imagepages: {
    fontSize: 18,
  },
  product: {
    imageBackground: "#fff",
  },
  formText: {
    fontSize: "medium",
    "& .FX-Article-Highlights-Blog": {
      marginBottom: 32,
    },
    "& .FX-ButtonExternalLink > span": {
      marginTop: "5px !important",
      marginBottom: "24px !important",
      marginRight: 5,
    },
    "& .FX-ProductHighlights-Youtube": {
      "& iframe": {
        witdh: "100%",
        maxWidth: "100%",
        border: 0,
      },
    },
    "& img": {
      maxWidth: "100%",
    },
    "& h1,h2": {
      fontSize: 32,
      fontFamily: "DM Serif Text Regular",
      lineHeight: "1.1",
      overflow: "hidden",
    },
    "& h3": {
      fontSize: 24,
      fontFamily: "inherit",
      lineHeight: "28px",
      fontWeight: 300,
      marginTop: 15,
      marginBottom: 5,
    },
    "& h2": {
      marginTop: 10,
      marginBottom: 10,
    },
    "&>p": {
      margin: 0,
      paddingTop: 0,
      paddingBottom: 17,
      fontSize: 17,
    },
    "&>br": {
      display: "none",
    },
    "& strong": {
      fontWeight: 600,
    },
    "& a": {
      color: "#0087ef",
    },
    "& .FX-Article-TableWrapper-custom-width": {
      width: "100%",
      overflowX: "auto",
      marginBottom: 20,
      marginTop: 20,
      "@media (min-width: 600px)": {
        width: "100vw!important",
        position: "relative",
        left: "calc(-50vw + 50%)",
        textAlign: "center",
        marginBottom: 40,
        marginTop: 30,
      },
      "&::-webkit-scrollbar": {
        "-webkit-appearance": "none",
        width: 11,
        height: 11,
      },
      "&::-webkit-scrollbar:vertical": {
        width: 11,
      },
      "&::-webkit-scrollbar:horizontal": {
        height: 11,
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: 8,
        backgroundColor: "rgba(0, 0, 0, .5)",
      },
      "& table": {
        tableLayout: "fixed",
        textAlign: "initial",
        "& td": {
          width: "unset!important",
        },
      },
    },
    "& table": {
      display: "inline-table",
      borderTopWidth: 0,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      borderLeftWidth: 0,
      marginBottom: 20,
      height: "auto!important",
      "@media (max-width: 600px)": {
        marginBottom: 10,
      },
    },
    "& td": {
      padding: 5,
    },
    "& tr": {
      borderColor: "#ced4d9",
      backgroundColor: "#fff",
    },
    "& th": {
      padding: 5,
      fontSize: 15,
      backgroundColor: "#3598db",
      borderColor: "#3598db",
      color: "#fff",
      textAlign: "center",
    },
    "& .textImageDescription": {
      fontSize: 17,
      fontStyle: "italic",
      color: "#8b8b8b",
    },
    "& .quote": {
      fontSize: 24,
      marginTop: 15,
      fontFamily: "inherit",
      fontWeight: 300,
      lineHeight: "28px",
      marginBottom: 5,
    },
    "& .quote2": {
      fontSize: 21,
      marginTop: 15,
      fontFamily: "inherit",
      fontWeight: 300,
      lineHeight: "30px",
      marginBottom: 5,
    },
    "& .textContentLinkSection": {
      paddingBottom: "5px!important",
    },
    "& .textContentLinkSection > .textContentHeader": {
      paddingBottom: "5px",
    },
    "& .textContentLink.textContentLinkSection": {
      paddingBottom: "14px!important",
    },
    "& .textContentLink": {
      color: "#8b8b8b",
      paddingBottom: 14,
      paddingTop: 0,
      margin: 0,
    },
    "& .textContentLink a": {
      color: "#8b8b8b !important",
      fontSize: "18px !important",
    },
    "& .textContentHeader": {
      color: "#8b8b8b !important",
      fontSize: 24,
      fontFamily: "inherit",
      lineHeight: "1.1",
      fontWeight: 300,
      marginTop: 15,
      marginBottom: 5,
    },
    "& .brandspageDescriptionHeader": {
      fontFamily: "DM Serif Text Regular",
      fontWeight: 900,
      textAlign: "center",
      fontSize: 32,
      lineHeight: "35px",
      marginBottom: 10,
    },
    "& ul.FX-ul-default": {
      padding: 0,
      margin: 0,
      marginBottom: 17,
      marginTop: 0,
      listStyleType: "none",
      textIndent: "-20px",
      paddingLeft: "20px",
    },
    "& li.FX-li-default::before": {
      content: '""',
      display: "inline-block",
      height: 13,
      width: 13,
      backgroundSize: 13,
      backgroundImage: 'url("/images/Bulletpoint.svg")',
      backgroundRepeat: "no-repeat",
      marginRight: 7,
    },
    "& .FX-HeadlineShortLink-root": {
      textAlign: "center",
      paddingBottom: 17,
      verticalAlign: "top",
      "& .FX-HeadlineShortLink-Headline": {
        fontSize: 20,
        lineHeight: "28px",
        fontWeight: 900,
        paddingBottom: 8,
      },
      "& .FX-HeadlineShortLink-Short": {
        paddingBottom: 8,
      },
      "& .FX-HeadlineShortLink-Link,a": {
        color: "#000",
      },
    },
    "& .FX-Newsletter-Blog-Snippet": {
      "& .FX-Newsletter-Blog-Snippet-Headline": {
        color: "#00AEEF",
        fontSize: 24,
        lineHeight: "32px",
        fontWeight: 900,
        margin: 0,
      },
      "& .FX-Newsletter-Blog-Snippet-Short": {
        color: "#FF4200",
        fontSize: 18,
        margin: 0,
      },
    },
    "& .FX-Highlight-2columns-2buttons": {
      border: "5px solid #59CBFE",
      position: "relative",
      margin: "32px 0px 50px 0px",
      padding: 8,
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      width: "100%",
      backgroundColor: "#fff",
      "& .FX-Highlight-Headline1": {
        "@media (min-width: 600px)": {
          left: -24,
        },
        top: -24,
        left: -12,
        padding: "5px 10px",
        margin: 0,
        display: "inline-block",
        position: "absolute",
        backgroundColor: "#00AEEF",
        fontFamily: "Roboto",
        fontSize: 26,
        color: "#fff",
        overflow: "initial",
        "&::after": {
          "@media (min-width: 600px)": {
            left: -19,
            borderWidth: "0 19px 9px",
          },
          content: '" "',
          display: "block",
          position: "absolute",
          left: -7,
          bottom: -9,
          borderColor: "rgba(0,0,0,0) #0174a6 rgba(0,0,0,0) rgba(0,0,0,0)",
          borderStyle: "inset solid inset inset",
          borderWidth: "0 7px 9px",
        },
      },
      "& .FX-Highlight-column1": {
        "@media (min-width: 600px)": {
          flex: "1",
        },
        flexGrow: "1",
        padding: 5,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
      },
      "& .FX-Highlight-column2": {
        flex: "1",
        flexGrow: "1",
        padding: 5,
      },
      "& .FX-Highlight-buttons": {
        paddingTop: 15,
        display: "flex",
        flexDirection: "column",
        "@media (min-width: 600px)": {
          alignItems: "flex-start",
        },
        alignItems: "center",
      },
      "& .FX-Highlight-buttons a .MuiButton-root": {
        backgroundColor: "#12A537",
        minWidth: 200,
      },
      "& .FX-Highlight-buttons .FX-ButtonExternalLink > span": {
        marginBottom: "10px !important",
      },
    },
  },
  palette: {
    primary: {
      main: "#fff",
    },
    secondary: {
      main: "#000",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#F2F2F0",
      secondary: "#fff",
      tertiary: "#F8F8F8",
    },
  },
  typography: {
    fontFamily: "Roboto",
    fontWeight: 300,
    fontWeightRegular: 300,
    fontWeightBold: 300,
    fontWeightMedium: 300,
    button: {
      fontWeight: 300,
      fontFamily: "Roboto",
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  overrides: {
    MuiButton: {
      endIcon: {
        marginLeft: 4,
        marginRight: -4,
      },
      textPrimary: {
        color: "#000", // required for useConfirm() dialog
      },
      textSecondary: {
        color: "#fff", // required for useConfirm() dialog
        backgroundColor: "#000",
      },
      root: {
        fontWeight: 600,
        textTransform: "inherit",
        paddingLeft: 20,
        paddingRight: 20,
        // borderRadius: theme.shape.borderRadius,
        "&&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.2)",
        },
      },
    },
    MuiButtonBase: {
      root: {
        fontWeight: 300,
        fontFamily: "Roboto",
      },
    },
    MuiToolbar: {
      regular: {
        minHeight: "80px",
        "@media (min-width: 600px)": {
          minHeight: "80px",
        },
      },
    },
    MuiCheckbox: {
      root: {
        "&&:hover, &&.Mui-checked:hover": {
          backgroundColor: "transparent",
        },
      },
    },
    MuiAccordionSummary: {
      content: {
        "&$expanded": {
          marginBottom: 5,
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        padding: 0,
      },
    },
    MuiTypography: {
      h3: {
        fontFamily: "DM Serif Text Regular",
        fontSize: 36,
        fontWeight: 900,
      },
      h6: {
        fontFamily: "DM Serif Text Regular",
        fontWeight: 600,
      },
    },
    MuiTableCell: {
      root: {
        padding: "4px 8px",
      },
    },
  },
};

export type TheCycleverseTheme = Theme & typeof customThemeOptions;

// Create a theme instance.
const theme = createTheme(customThemeOptions as any);

export default theme;
