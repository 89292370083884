/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createProduct = /* GraphQL */ `
  mutation CreateProduct($input: CreateProductInput!) {
    createProduct(input: $input) {
      id
      seoId
      title
      link
      advertiser
      brand
      price {
        amount
        amountWithoutDiscount
        decimalOffset
        currency
      }
      description
      images {
        id
        uri
        alt
        copyright
      }
      imagesSmall {
        id
        uri
        alt
        copyright
      }
      category
      locale
      visibility
      deliveryCost {
        amount
        amountWithoutDiscount
        decimalOffset
        currency
      }
      color
      inStock
      inSale
      ean
      gender
      size
      likes
      importTimestamp
      importType
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      id
      seoId
      title
      link
      advertiser
      brand
      price {
        amount
        amountWithoutDiscount
        decimalOffset
        currency
      }
      description
      images {
        id
        uri
        alt
        copyright
      }
      imagesSmall {
        id
        uri
        alt
        copyright
      }
      category
      categoryLock
      locale
      visibility
      deliveryCost {
        amount
        amountWithoutDiscount
        decimalOffset
        currency
      }
      color
      inStock
      inSale
      ean
      gender
      size
      likes
      importTimestamp
      importType
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct($input: DeleteProductInput!) {
    deleteProduct(input: $input) {
      id
      seoId
      title
      link
      advertiser
      brand
      price {
        amount
        amountWithoutDiscount
        decimalOffset
        currency
      }
      description
      images {
        id
        uri
        alt
        copyright
      }
      imagesSmall {
        id
        uri
        alt
        copyright
      }
      category
      locale
      visibility
      deliveryCost {
        amount
        amountWithoutDiscount
        decimalOffset
        currency
      }
      color
      inStock
      inSale
      ean
      gender
      size
      likes
      importTimestamp
      importType
    }
  }
`;
export const createArticle = /* GraphQL */ `
  mutation CreateArticle($input: CreateArticleInput!) {
    createArticle(input: $input) {
      id
      title
      short
      content
      images {
        id
        uri
        alt
        copyright
      }
      category
      locale
      author
      keywords
      visibility
      copyright
      releaseTimestamp
      metadataTitle
      metadataDescription
    }
  }
`;
export const updateArticle = /* GraphQL */ `
  mutation UpdateArticle($input: UpdateArticleInput!) {
    updateArticle(input: $input) {
      id
      title
      short
      content
      images {
        id
        uri
        alt
        copyright
      }
      category
      locale
      author
      keywords
      visibility
      copyright
      releaseTimestamp
      metadataTitle
      metadataDescription
    }
  }
`;
export const deleteArticle = /* GraphQL */ `
  mutation DeleteArticle($input: DeleteArticleInput!) {
    deleteArticle(input: $input) {
      id
      title
      short
      content
      images {
        id
        uri
        alt
        copyright
      }
      category
      locale
      author
      keywords
      visibility
      copyright
      releaseTimestamp
      metadataTitle
      metadataDescription
    }
  }
`;
export const createAdvertiserCategory = /* GraphQL */ `
  mutation CreateAdvertiserCategory($input: CreateAdvertiserCategoryInput!) {
    createAdvertiserCategory(input: $input) {
      id
      advertiser
      advertiserCategory
      actualCategory
      assignedCategory
    }
  }
`;
export const updateAdvertiserCategory = /* GraphQL */ `
  mutation UpdateAdvertiserCategory($input: UpdateAdvertiserCategoryInput!) {
    updateAdvertiserCategory(input: $input) {
      id
      advertiser
      advertiserCategory
      actualCategory
      assignedCategory
    }
  }
`;
export const deleteAdvertiserCategory = /* GraphQL */ `
  mutation DeleteAdvertiserCategory($input: DeleteAdvertiserCategoryInput!) {
    deleteAdvertiserCategory(input: $input) {
      id
      advertiser
      advertiserCategory
      actualCategory
      assignedCategory
    }
  }
`;
export const createCategoryInfo = /* GraphQL */ `
  mutation CreateCategoryInfo($input: CreateCategoryInfoInput!) {
    createCategoryInfo(input: $input) {
      id
      name
      count
    }
  }
`;
export const updateCategoryInfo = /* GraphQL */ `
  mutation UpdateCategoryInfo($input: UpdateCategoryInfoInput!) {
    updateCategoryInfo(input: $input) {
      id
      name
      count
    }
  }
`;
export const deleteCategoryInfo = /* GraphQL */ `
  mutation DeleteCategoryInfo($input: DeleteCategoryInfoInput!) {
    deleteCategoryInfo(input: $input) {
      id
      name
      count
    }
  }
`;
export const createText = /* GraphQL */ `
  mutation CreateText($input: CreateTextInput!) {
    createText(input: $input) {
      id
      content
    }
  }
`;
export const updateText = /* GraphQL */ `
  mutation UpdateText($input: UpdateTextInput!) {
    updateText(input: $input) {
      id
      content
    }
  }
`;
export const deleteText = /* GraphQL */ `
  mutation DeleteText($input: DeleteTextInput!) {
    deleteText(input: $input) {
      id
      content
    }
  }
`;

export const createPriceAlert = /* GraphQL */ `
  mutation CreatePriceAlert($productId: String!, $productLink: String!, $email: String!, $userId: String!) {
    createPriceAlert(productId: $productId, productLink: $productLink, email: $email, userId: $userId) {
      id
      emailConfirmed
      name
    }
  }
`;
export const removePriceAlert = /* GraphQL */ `
  mutation RemovePriceAlert($alertId: String!) {
    removePriceAlert(alertId: $alertId) {
      id
      name
    }
  }
`;
export const confirmPriceAlert = /* GraphQL */ `
  mutation ConfirmPriceAlert($alertId: String!) {
    confirmPriceAlert(alertId: $alertId) {
      id
      name
    }
  }
`;
export const sendAnalyticsBatch = /* GraphQL */ `
  mutation SendAnalyticsBatch($events: [AnalyticsEvent]) {
    sendAnalyticsBatch(events: $events)
  }
`;
