export type StorageType = "sessionStorage" | "localStorage";

const storageGet = (type: StorageType, key: string, fallback: any): any => {
  if (type == "localStorage" && typeof localStorage === "undefined") {
    // Guard for SSR
    return fallback;
  }
  if (type == "sessionStorage" && typeof sessionStorage === "undefined") {
    // Guard for SSR
    return fallback;
  }

  const storage = window[type];
  const value = storage.getItem(key);
  if (!value) {
    return fallback;
  }
  return JSON.parse(value);
};

const storageSet = (type: StorageType, key: string, value: any) => {
  if (type == "localStorage" && typeof localStorage === "undefined") {
    // Guard for SSR
    return;
  }
  if (type == "sessionStorage" && typeof sessionStorage === "undefined") {
    // Guard for SSR
    return;
  }

  const storage = window[type];
  try {
    storage.setItem(key, JSON.stringify(value));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Setting item in ${type} failed. Please empty data`);
  }
};

export const localStorageSet = (key, value) => {
  storageSet("localStorage", key, value);
};

export const localStorageGet = (key, fallback) => {
  return storageGet("localStorage", key, fallback);
};

export const sessionStorageSet = (key, value) => {
  storageSet("sessionStorage", key, value);
};

export const sessionStorageGet = (key, fallback) => {
  return storageGet("sessionStorage", key, fallback);
};
