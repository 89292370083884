import Cookies from "universal-cookie";

const PREFIX = "AMPLCONS_";

export class ConsentStorage {
  static cks = null;

  // the promise returned from sync function
  static syncPromise = null;

  static getCookies() {
    if (!ConsentStorage.cks) ConsentStorage.cks = new Cookies();
    return ConsentStorage.cks;
  }

  static from(request) {
    if (request) {
      ConsentStorage.cks = new Cookies(request);
    }
  }

  static reset() {
    ConsentStorage.cks = new Cookies();
  }

  static setItem(key, value) {
    const cookies = ConsentStorage.getCookies();
    cookies.set(PREFIX + key, value, {
      path: "/",
      maxAge: 60 * 60 * 24 * 365,
      secure: false,
      httpOnly: false,
      sameSite: "strict",
    });
    return value;
  }

  static getItem(key) {
    const cookies = ConsentStorage.getCookies();
    const value = cookies.get(PREFIX + key);
    return value;
  }

  static getItemAsBool(key) {
    const value = ConsentStorage.getItem(key);
    var isTrueSet = String(value) === "true";
    return isTrueSet;
  }
}
