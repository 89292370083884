import { default as productCategories } from "../../../categoriesExplodedTree.json";
import { default as categoriesMapping } from "../../../categoriesMapping.json";
import Cache from "../../../lib/cache";

export function getCategoryByName(id) {
  const categoryId = categoriesMapping[id];
  if (!categoryId) {
    return null;
  }
  return getCategoryById(categoryId);
}

export function getCategoryById(id) {
  if (Cache.get(id)) {
    return Cache.get(id);
  }
  const categoryObj = productCategories[id];
  if (!categoryObj) {
    return null;
  }
  const res = {
    pathNames: categoryObj.path.split("|").map((item) => item.split(":")[0]),
    pathNamesRev: categoryObj.path
      .split("|")
      .map((item) => item.split(":")[0])
      .reverse(),
    path: categoryObj.path.split("|").map((item) => {
      return {
        name: item.split(":")[0],
        id: item.split(":")[1],
      };
    }),
    children: categoryObj.hasOwnProperty("children")
      ? categoryObj.children.split("|").map((item) => {
          return {
            name: item.split(":")[0],
            id: item.split(":")[1],
          };
        })
      : [],
    id: id,
  };
  Cache.set(id, res);
  return res;
}
