import AdContext from "./AdContext";
import { GPTProvider } from "goopubtag";
import Router from "next/router";
import environment from "src/environment";
import { makeStyles } from "@material-ui/core/styles";
import { useEffectOnce } from "react-use";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  adfooter: {
    position: "sticky",
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const AdProvider = (props) => {
  const classes = useStyles();
  const [adsEnabled, setAdsEnabled] = useState(false);
  const [footerAd, setFooterAd] = useState();

  useEffectOnce(() => {
    Router.events.on("routeChangeStart", () => {
      setFooterAd(null);
    });
  });

  return (
    <AdContext.Provider value={{ adsEnabled, setAdsEnabled, setFooterAd }}>
      {adsEnabled ? (
        <GPTProvider networkId={environment.adNetworkId}>
          <>
            {props.children}
            {footerAd && <div className={classes.adfooter}>{footerAd}</div>}
          </>
        </GPTProvider>
      ) : (
        <>{props.children}</>
      )}
    </AdContext.Provider>
  );
};
export default AdProvider;
