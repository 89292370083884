import Cookies from "universal-cookie";
import environment from "../environment";
import nanoid from "nanoid";

const PREFIX = `CVRS_${environment.name}_`;

export class AuthStorage {
  static cks = null;

  // the promise returned from sync function
  static syncPromise = null;

  static getCookies() {
    if (!AuthStorage.cks) {
      AuthStorage.cks = new Cookies();
    }
    return AuthStorage.cks;
  }

  static from(request) {
    if (request) {
      AuthStorage.cks = new Cookies(request);
      // console.log("Initialized AuthStorage from cookies");
    }
  }

  static reset() {
    // console.log("reset authStore");
    AuthStorage.clear();
    AuthStorage.cks = new Cookies();
  }

  static getDomain() {
    if (typeof window !== "undefined") {
      return window.location.hostname;
    } else {
      return "thecycleverse.com";
    }
  }

  // set item with the key
  static setItem(key, value) {
    // console.log("set cookie " + key);
    const cookies = AuthStorage.getCookies();
    cookies.set(PREFIX + key, value, {
      path: "/",
      domain: AuthStorage.getDomain(),
      maxAge: 60 * 60 * 24 * 365,
      secure: false,
      httpOnly: false,
      sameSite: "strict",
    });
    return value;
  }

  // get item with the key
  static getItem(key) {
    // console.debug("get cookie " + key);
    const cookies = AuthStorage.getCookies();
    const value = cookies.get(PREFIX + key);
    return value;
  }

  static getAll() {
    // console.log("getAll cookies");
    const cookies = AuthStorage.getCookies();
    return cookies.getAll();
  }

  // remove item with the key
  static removeItem(key) {
    // console.log("remove cookie " + key);
    const cookies = AuthStorage.getCookies();
    cookies.remove(PREFIX + key, {
      path: "/",
      domain: AuthStorage.getDomain(),
    });
  }

  // clear out the storage
  static clear() {
    // console.log("clear cookies");
    const cookies = AuthStorage.getCookies();
    const all = cookies.getAll();
    Object.keys(all).forEach(function(key) {
      if (key.startsWith(PREFIX)) {
        // console.log("remove: " + key);
        AuthStorage.removeItem(key.slice(0, PREFIX.length));
      }
    });
  }

  // If the storage operations are async(i.e AsyncStorage)
  // Then you need to sync those items into the memory in this method
  static sync() {
    if (!AuthStorage.syncPromise) {
      AuthStorage.syncPromise = new Promise((res) => {
        res();
      });
    }
    return AuthStorage.syncPromise;
  }

  static getUserId() {
    // We're using CognitoIdentityId since it's already stored as token
    const identityPoolId = environment.backend.aws_cognito_identity_pool_id;
    return AuthStorage.getItem("CognitoIdentityId-" + identityPoolId);
  }

  static newUserId() {
    return `nonCognito:${nanoid(32)}`;
  }

  static setUserId(userId) {
    // We're using CognitoIdentityId since it's already stored as token
    const identityPoolId = environment.backend.aws_cognito_identity_pool_id;
    return AuthStorage.setItem("CognitoIdentityId-" + identityPoolId, userId);
  }

  static getLastAuthUser() {
    const webClientId = environment.backend.aws_user_pools_web_client_id;
    return AuthStorage.getItem(`CognitoIdentityServiceProvider.${webClientId}.LastAuthUser`);
  }

  static parseAccessToken(token) {
    if (!token) {
      return null;
    }
    const tokenSections = (token || "").split(".");
    if (tokenSections.length < 2) {
      return null;
    }
    const decodeBase64 = window.atob;
    const payloadJSON = decodeBase64(tokenSections[1]);
    const payload = JSON.parse(payloadJSON);
    return payload;
  }

  static async refreshAccessToken() {
    const authUser = AuthStorage.getLastAuthUser();
    if (!authUser) {
      return null;
    }
    const webClientId = environment.backend.aws_user_pools_web_client_id;
    const refreshToken = AuthStorage.getItem(`CognitoIdentityServiceProvider.${webClientId}.${authUser}.refreshToken`);
    if (!refreshToken) {
      return null;
    }
    const res = await fetch(`https://cognito-idp.${environment.backend.aws_cognito_region}.amazonaws.com/`, {
      headers: {
        "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
        "Content-Type": "application/x-amz-json-1.1",
      },
      mode: "cors",
      cache: "no-cache",
      method: "POST",
      body: JSON.stringify({
        ClientId: environment.backend.aws_user_pools_web_client_id,
        AuthFlow: "REFRESH_TOKEN_AUTH",
        AuthParameters: {
          REFRESH_TOKEN: refreshToken,
        },
      }),
    });

    if (res.status != 200) {
      return null;
    }
    const data = await res.json();
    const authResult = data["AuthenticationResult"];
    const accessToken = authResult["AccessToken"];
    AuthStorage.setItem(`CognitoIdentityServiceProvider.${webClientId}.${authUser}.accessToken`, accessToken);
    return accessToken;
  }

  static periodicRefreshAccessToken() {
    const timeout = 30 * 60 * 60 * 1000;
    return setInterval(() => {
      AuthStorage.refreshAccessToken();
    }, timeout);
  }

  static async getAccessToken() {
    const authUser = AuthStorage.getLastAuthUser();
    if (!authUser) {
      return null;
    }
    const webClientId = environment.backend.aws_user_pools_web_client_id;
    const token = AuthStorage.getItem(`CognitoIdentityServiceProvider.${webClientId}.${authUser}.accessToken`);
    if (!token) {
      return null;
    }
    const payload = AuthStorage.parseAccessToken(token);
    const expirationUnixTS = payload["exp"];
    const expirationMS = expirationUnixTS * 1000;
    const refreshWindow = 6 * 60 * 60 * 1000;
    if (expirationMS - refreshWindow <= new Date().getTime()) {
      return await AuthStorage.refreshAccessToken();
    }
    return token;
  }

  static async getUserGroups() {
    const noGroups = [];
    const token = await AuthStorage.getAccessToken();
    const payload = AuthStorage.parseAccessToken(token);
    const groups = payload ? payload["cognito:groups"] : noGroups;
    return groups;
  }
}
