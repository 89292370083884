import { GraphQLClient, gql } from "graphql-request";

import environment from "../environment";

export function createClient(accessToken?) {
  const headers = {};
  if (accessToken) {
    headers["authorization"] = accessToken;
  } else {
    headers["x-api-key"] = environment.backend.aws_appsync_apiKey;
  }

  const graphQLClient = new GraphQLClient(environment.backend.aws_appsync_graphqlEndpoint, {
    headers,
  });
  return graphQLClient;
}

export type GqlFetcherType = (args: [query: string, variables:string]) => Promise<any>;

export function createFetcher(client?): GqlFetcherType {
  if (!client) {
    client = createClient();
  }
  return async (args) => {
    const [query, variables] = args;

    if (!query || !variables) {
      return null;
    }

    var vars = "";
    try {
      vars = JSON.parse(variables);
    } catch (e) {
      console.error(`Variables not parseable as json. ${variables}`, e);
      return null;
    }

    try {
      return await client.request(
        gql`
          ${query}
        `,
        vars
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Failed to call api", e);

      if (
        e.response &&
        e.response.errors &&
        e.response.errors.length > 0 &&
        e.response.errors[0].message == "Token has expired."
      ) {
        if (typeof window !== "undefined") {
          window.location.href = "/account/refresh";
        }
      } else {
        throw e;
      }
    }
  };
}
