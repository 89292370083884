import LRUCache from 'lru-cache';

export default class Cache {

  static cache = null;

  static getCache() {
    if (!Cache.cache) {
        Cache.cache = new LRUCache({
            max: 10000,
            maxAge: 1000 * 60 * 60 // 1hour
         });
    }
    return Cache.cache;
  }
  
  static set(key, value) {
    const cache = this.getCache();
    cache.set(key, value);
    return value;
  }

  static get(key) {
    const cache = this.getCache();
    const value = cache.get(key);
    return value;
  }
}