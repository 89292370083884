/* eslint-disable */
export const likeProduct = /* GraphQL */ `
  query LikeProduct($productId: String!) {
    likeProduct(productId: $productId)
  }
`;
export const contact = /* GraphQL */ `
  query Contact($message: String!, $locale: String, $region: String) {
    contact(message: $message, locale: $locale, region: $region)
  }
`;
export const sendAnalytics = /* GraphQL */ `
  query SendAnalytics(
    $env: String!
    $userId: String!
    $sessionId: String!
    $eventName: String!
    $fbc: String
    $documentReferrer: String
    $dataJson: String
  ) {
    sendAnalytics(
      env: $env
      userId: $userId
      sessionId: $sessionId
      eventName: $eventName
      fbc: $fbc
      documentReferrer: $documentReferrer
      dataJson: $dataJson
    )
  }
`;
export const sendLogs = /* GraphQL */ `
  query SendLogs($level: String!, $msg: String!) {
    sendLogs(level: $level, msg: $msg)
  }
`;
export const searchRecommendation = /* GraphQL */ `
  query SearchRecommendation($searchString: String!, $locale: String, $region: String) {
    searchRecommendation(searchString: $searchString, locale: $locale, region: $region) {
      searchString
      recommendedTermsProducts
      recommendedTermsArticles
      recommendedCategories {
        key
        count
      }
    }
  }
`;
export const queryProducts = /* GraphQL */ `
  query QueryProducts(
    $action: String!
    $searchString: String
    $category: String
    $categories: String
    $categoryFilter: String
    $idFilter: String
    $excludeIds: String
    $brands: String
    $sort: String
    $priceMin: Int
    $priceMax: Int
    $visibility: String
    $advertiser: String
    $advertiserCategory: String
    $productCondition: String
    $sale: String
    $ean: String
    $seoId: String
    $offset: Int
    $size: Int
    $locale: String
    $region: String
    $recordTypes: String
  ) {
    queryProducts(
      action: $action
      searchString: $searchString
      category: $category
      categories: $categories
      categoryFilter: $categoryFilter
      idFilter: $idFilter
      excludeIds: $excludeIds
      brands: $brands
      sort: $sort
      priceMin: $priceMin
      priceMax: $priceMax
      visibility: $visibility
      advertiser: $advertiser
      advertiserCategory: $advertiserCategory
      productCondition: $productCondition
      sale: $sale
      ean: $ean
      seoId: $seoId
      offset: $offset
      size: $size
      locale: $locale
      region: $region
      recordTypes: $recordTypes
    ) {
      items {
        id
        qualityRating
        recordType
        bikeSlug
        seoId
        title
        link
        advertiser
        brand
        price {
          amount
          amountWithoutDiscount
          decimalOffset
          currency
        }
        priceHistory {
          amount
          date
        }
        description
        descriptionNormalized
        specificationGroups {
          key
          value {
            key
            value
          }
        }
        images {
          id
          uri
          alt
          copyright
        }
        imagesSmall {
          uri
          alt
          copyright
        }
        renditionSmall
        renditionMedium
        renditionLarge
        renditionOpenGraph
        productCondition
        category
        locale
        visibility
        deliveryCost {
          amount
          amountWithoutDiscount
          decimalOffset
          currency
        }
        color
        inStock
        inSale
        ean
        gender
        size
        likes
        bikeDetail {
          barTape
          bottomBracket
          brakeLever
          brakesDiscDiameter
          brakesFront
          brakesRear
          brakeSystem
          brakeBackPedal
          cassette
          chain
          crankLenght
          crankset
          detailDescription
          fork
          forkMaterial
          frame
          frameMaterial
          frameType
          frontDerailleurs
          gearCount
          gearShift
          handleBar
          headset
          hubFront
          hubRear
          lightFront
          lightRear
          modelYear
          mudGuard
          parkingSupport
          pedals
          rearDerailleurs
          rims
          reachMM
          saddle
          seatPost
          shifters
          shiftType
          spacer
          spokes
          stem
          suspensionTravelFront
          suspensionTravelRear
          tiresFront
          tiresRear
          weight
          maxWeight
          wheelset
          wheelSize
          assistanceLevel
          batteryCapacity
          batteryPack
          batteryPosition
          charger
          dynamo
          displayType
          drivePosition
          engine
          engineManufacturer
          enginePosition
          motorTorque
          batteryLoadingTime
          batteryIsRemovable
          speed
          tractionHelp
          stackReachRatio
          frontCenterMM
          wheelbaseMM
          rakeMM
          trailMM
          stackMM
          reachMM
          topTubeLengthMM
          seatTubeAngle
          seatTubeLengthMM
          headTubeAngle
          headTubeLengthMM
          chainstayLengthMM
          bottomBracketDropMM
          bottomBracketHeightMM
        }
      }
      brands {
        key
        count
      }
      categories {
        key
        count
      }
      inaccuracy
      total
      offset
      size
    }
  }
`;

export const queryBikesShallow = /* GraphQL */ `
  query QueryBikes($action: String!, $category: String, $excludeIds: String, $size: Int, $visibility: String) {
    queryBikes(action: $action, category: $category, excludeIds: $excludeIds, size: $size, visibility: $visibility) {
      items {
        id
        componentRating {
          gearshift
          undercarriage
          frame
          tires
          brakes
          ebike
        }
        visibility
        qualityRating
        recordType
        bikeFamily
        bikeSeries
        seoId
        modelName
        brandName
        year
        slug
        price {
          amount
          amountWithoutDiscount
          decimalOffset
          currency
        }
        priceHistory {
          amount
          date
        }
        renditionSmall
        renditionMedium
        renditionLarge
        imagesSmall {
          uri
        }
        images {
          uri
        }
        category
      }
      inaccuracy
      total
      offset
      size
    }
  }
`;

export const queryBikes = /* GraphQL */ `
  query QueryBikes(
    $action: String!
    $bikeId: String
    $excludeIds: String
    $bikeFamily: String
    $bikeSeries: String
    $visibility: String
  ) {
    queryBikes(
      action: $action
      bikeId: $bikeId
      excludeIds: $excludeIds
      bikeFamily: $bikeFamily
      bikeSeries: $bikeSeries
      visibility: $visibility
    ) {
      items {
        id
        seoId
        recordType
        bikeFamily
        bikeSeries
        slug
        brandName
        modelName
        year
        category
        visibility
        qualityMetrics {
          affiliateRecordCount
          priceConsistencyRating
          totalConsistencyRating
          totalQualityRating
          categoryConsistencyRating
          eanConflicts
          measuredAt
        }
        sizing {
          sizeName
          measurement
          fromCm
          toCm
        }
        bikeGeometries {
          sizeName
          wheelSizeInchesX10
          stack
          reach
          toptubelength
          seattube
          seatangleX10
          steeringtubelength
          steeringtubeangle
          bottombracketdrop
          pedalhubheight
          chainstaylength
          wheelbase
          standoverheight
          trailcut
          forkprebend
        }
        componentRating {
          gearshift
          undercarriage
          frame
          tires
          brakes
          ebike
        }
        qualityRating
        speedEstimate {
          maxSpeedKph
          maxSpeedKphComparables
        }
        comparables {
          cheaper {
            seoId
            img
            brandName
            modelName
          }
          moreExpensive {
            seoId
            img
            brandName
            modelName
          }
        }
        priceHistory {
          amount
          date
          advertiser
        }
        price {
          amount
          amountWithoutDiscount
          decimalOffset
          currency
        }
        renditionSmall
        renditionMedium
        renditionLarge
        imagesSmall {
          uri
        }
        images {
          uri
        }
        descriptionNormalized
        affiliateRecords {
          id
          recordType
          seoId
          title
          link
          advertiser
          brand
          price {
            amount
            amountWithoutDiscount
            decimalOffset
            currency
          }
          priceHistory {
            amount
            date
          }
          description
          images {
            id
            uri
            alt
            copyright
          }
          imagesSmall {
            uri
            alt
            copyright
          }
          renditionSmall
          renditionMedium
          renditionLarge
          renditionOpenGraph
          category
          locale
          visibility
          deliveryCost {
            amount
            amountWithoutDiscount
            decimalOffset
            currency
          }
          color
          inStock
          inSale
          ean
          gender
          size
          likes
          normalized {
            brandName
            modelName
            year
            gender
            batteryWattHours
            shape
            color {
              colors {
                red
                green
                blue
                primaryName
                nameByLocale {
                  de
                  en
                }
                hexValue
              }
              id
            }
            frameSize {
              cm
            }
            frameSizeLetter {
              letter
            }
            wheelSize {
              inchesX10
            }
          }
          bikeDetail {
            barTape
            bottomBracket
            brakeLever
            brakesDiscDiameter
            brakesFront
            brakesRear
            brakeSystem
            brakeBackPedal
            cassette
            chain
            crankLenght
            crankset
            detailDescription
            fork
            forkMaterial
            frame
            frameMaterial
            frameType
            frontDerailleurs
            gearCount
            gearShift
            handleBar
            headset
            hubFront
            hubRear
            lightFront
            lightRear
            modelYear
            mudGuard
            parkingSupport
            pedals
            rearDerailleurs
            rims
            reachMM
            saddle
            seatPost
            shifters
            shiftType
            spacer
            spokes
            stem
            tiresFront
            tiresRear
            weight
            maxWeight
            wheelset
            wheelSize
            assistanceLevel
            batteryCapacity
            batteryPack
            batteryPosition
            charger
            dynamo
            displayType
            drivePosition
            engine
            engineManufacturer
            enginePosition
            motorTorque
            batteryLoadingTime
            batteryIsRemovable
            speed
            tractionHelp
            stackReachRatio
            frontCenterMM
            wheelbaseMM
            rakeMM
            trailMM
            stackMM
            reachMM
            topTubeLengthMM
            seatTubeAngle
            seatTubeLengthMM
            headTubeAngle
            headTubeLengthMM
            chainstayLengthMM
            bottomBracketDropMM
            bottomBracketHeightMM
          }
        }
      }
      brands {
        key
        count
      }
      categories {
        key
        count
      }
      inaccuracy
      total
      offset
      size
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      recordType
      bikeSlug
      seoId
      title
      link
      advertiser
      brand
      price {
        amount
        amountWithoutDiscount
        decimalOffset
        currency
      }
      priceHistory {
        amount
        date
      }
      description
      descriptionNormalized
      specificationGroups {
        key
        value {
          key
          value
        }
      }
      images {
        id
        uri
        alt
        copyright
      }
      imagesSmall {
        uri
        alt
        copyright
      }
      renditionSmall
      renditionMedium
      renditionLarge
      renditionOpenGraph
      productCondition
      category
      locale
      visibility
      deliveryCost {
        amount
        amountWithoutDiscount
        decimalOffset
        currency
      }
      color
      inStock
      inSale
      ean
      gender
      size
      likes
      qualityRating
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts($filter: ModelProductFilterInput, $limit: Int, $nextToken: String) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        recordType
        bikeSlug
        seoId
        title
        link
        advertiser
        brand
        description
        descriptionNormalized
        specificationGroups {
          key
          value {
            key
            value
          }
        }
        category
        locale
        price {
          amount
          amountWithoutDiscount
          decimalOffset
          currency
        }
        priceHistory {
          amount
          date
        }
        deliveryCost {
          amount
          decimalOffset
          currency
        }
        images {
          uri
          alt
        }
        imagesSmall {
          uri
          alt
        }
        renditionSmall
        renditionMedium
        renditionLarge
        renditionOpenGraph
        productCondition
        likes
      }
      nextToken
    }
  }
`;
export const itemsByCategory = /* GraphQL */ `
  query ItemsByCategory(
    $visibility: String
    $category: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsByCategory(
      visibility: $visibility
      category: $category
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        recordType
        bikeSlug
        seoId
        title
        link
        advertiser
        brand
        description
        descriptionNormalized
        specificationGroups {
          key
          value {
            key
            value
          }
        }
        category
        locale
        price {
          amount
          amountWithoutDiscount
          decimalOffset
          currency
        }
        priceHistory {
          amount
          date
        }
        deliveryCost {
          amount
          decimalOffset
          currency
        }
        images {
          uri
          alt
        }
        imagesSmall {
          uri
          alt
        }
        renditionSmall
        renditionMedium
        renditionLarge
        renditionOpenGraph
        productCondition
        likes
      }
      nextToken
    }
  }
`;
export const itemsBySeoId = /* GraphQL */ `
  query ItemsBySeoId(
    $visibility: String
    $seoId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsBySeoId(
      visibility: $visibility
      seoId: $seoId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        recordType
        bikeSlug
        seoId
        title
        link
        advertiser
        brand
        description
        descriptionNormalized
        specificationGroups {
          key
          value {
            key
            value
          }
        }
        category
        locale
        price {
          amount
          amountWithoutDiscount
          decimalOffset
          currency
        }
        priceHistory {
          amount
          date
        }
        deliveryCost {
          amount
          decimalOffset
          currency
        }
        images {
          uri
          alt
        }
        imagesSmall {
          uri
          alt
        }
        renditionSmall
        renditionMedium
        renditionLarge
        renditionOpenGraph
        productCondition
        likes
        ean
        bikeDetail {
          barTape
          bottomBracket
          brakeLever
          brakesDiscDiameter
          brakesFront
          brakesRear
          brakeSystem
          brakeBackPedal
          cassette
          chain
          crankLenght
          crankset
          detailDescription
          fork
          forkMaterial
          frame
          frameMaterial
          frameType
          frontDerailleurs
          gearCount
          gearShift
          handleBar
          headset
          hubFront
          hubRear
          lightFront
          lightRear
          modelYear
          mudGuard
          parkingSupport
          pedals
          rearDerailleurs
          rims
          reachMM
          saddle
          seatPost
          shifters
          shiftType
          spacer
          spokes
          stem
          tiresFront
          tiresRear
          weight
          maxWeight
          wheelset
          wheelSize
          assistanceLevel
          batteryCapacity
          batteryPack
          batteryPosition
          charger
          dynamo
          displayType
          drivePosition
          engine
          engineManufacturer
          enginePosition
          motorTorque
          batteryLoadingTime
          batteryIsRemovable
          speed
          tractionHelp
          stackReachRatio
          frontCenterMM
          wheelbaseMM
          rakeMM
          trailMM
          stackMM
          reachMM
          topTubeLengthMM
          seatTubeAngle
          seatTubeLengthMM
          headTubeAngle
          headTubeLengthMM
          chainstayLengthMM
          bottomBracketDropMM
          bottomBracketHeightMM
        }
      }
      nextToken
    }
  }
`;
export const itemsBySeoIdVisibility = /* GraphQL */ `
  query ItemsBySeoIdVisibility(
    $visibility: ModelStringKeyConditionInput
    $seoId: String
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsBySeoIdVisibility(
      visibility: $visibility
      seoId: $seoId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        recordType
        bikeSlug
        seoId
        title
        link
        advertiser
        brand
        description
        descriptionNormalized
        specificationGroups {
          key
          value {
            key
            value
          }
        }
        category
        locale
        price {
          amount
          amountWithoutDiscount
          decimalOffset
          currency
        }
        priceHistory {
          amount
          date
        }
        deliveryCost {
          amount
          decimalOffset
          currency
        }
        images {
          uri
          alt
        }
        imagesSmall {
          uri
          alt
        }
        renditionSmall
        renditionMedium
        renditionLarge
        renditionOpenGraph
        productCondition
        likes
        ean
        visibility
        bikeDetail {
          barTape
          bottomBracket
          brakeLever
          brakesDiscDiameter
          brakesFront
          brakesRear
          brakeSystem
          brakeBackPedal
          cassette
          chain
          crankLenght
          crankset
          detailDescription
          fork
          forkMaterial
          frame
          frameMaterial
          frameType
          frontDerailleurs
          gearCount
          gearShift
          handleBar
          headset
          hubFront
          hubRear
          lightFront
          lightRear
          modelYear
          mudGuard
          parkingSupport
          pedals
          rearDerailleurs
          rims
          reachMM
          saddle
          seatPost
          shifters
          shiftType
          spacer
          spokes
          stem
          tiresFront
          tiresRear
          weight
          maxWeight
          wheelset
          wheelSize
          assistanceLevel
          batteryCapacity
          batteryPack
          batteryPosition
          charger
          dynamo
          displayType
          drivePosition
          engine
          engineManufacturer
          enginePosition
          motorTorque
          batteryLoadingTime
          batteryIsRemovable
          speed
          tractionHelp
          stackReachRatio
          frontCenterMM
          wheelbaseMM
          rakeMM
          trailMM
          stackMM
          reachMM
          topTubeLengthMM
          seatTubeAngle
          seatTubeLengthMM
          headTubeAngle
          headTubeLengthMM
          chainstayLengthMM
          bottomBracketDropMM
          bottomBracketHeightMM
        }
      }
      nextToken
    }
  }
`;
export const createItemsBySeoIdBatchQuery = (seoIds) => {
  return /* GraphQL */ `
    query QueryProducts(
      $visibility: String
    ) {
      ${seoIds.map((seoId, i) => {
        return /* GraphQL */ `
          seoId${i}: queryProducts(
            action: "getBySeoId"
            visibility: $visibility
            seoId: "${seoId}"
            size: 10
          ) {
            items {
              id
              recordType
              bikeSlug
              seoId
              title
              link
              advertiser
              brand
              description
              descriptionNormalized
              specificationGroups {
                key
                value {
                  key
                  value
                }
              }
              category
              locale
              price {
                amount
                amountWithoutDiscount
                decimalOffset
                currency
              }
              priceHistory {
                amount
                date
              }
              deliveryCost {
                amount
                decimalOffset
                currency
              }
              images {
                uri
                alt
              }
              imagesSmall {
                uri
                alt
              }
              renditionSmall
              renditionMedium
              renditionLarge
              renditionOpenGraph
              productCondition
              likes
              ean
              bikeDetail {
                barTape
                bottomBracket
                brakeLever
                brakesDiscDiameter
                brakesFront
                brakesRear
                brakeSystem
                brakeBackPedal
                cassette
                chain
                crankLenght
                crankset
                detailDescription
                fork
                forkMaterial
                frame
                frameMaterial
                frameType
                frontDerailleurs
                gearCount
                gearShift
                handleBar
                headset
                hubFront
                hubRear
                lightFront
                lightRear
                modelYear
                mudGuard
                parkingSupport
                pedals
                rearDerailleurs
                rims
                reachMM
                saddle
                seatPost
                shifters
                shiftType
                spacer
                spokes
                stem
                tiresFront
                tiresRear
                weight
                maxWeight
                wheelset
                wheelSize
                assistanceLevel
                batteryCapacity
                batteryPack
                batteryPosition
                charger
                dynamo
                displayType
                drivePosition
                engine
                engineManufacturer
                enginePosition
                motorTorque
                batteryLoadingTime
                batteryIsRemovable
                speed
                tractionHelp
                stackReachRatio
                frontCenterMM
                wheelbaseMM
                rakeMM
                trailMM
                stackMM
                reachMM
                topTubeLengthMM
                seatTubeAngle
                seatTubeLengthMM
                headTubeAngle
                headTubeLengthMM
                chainstayLengthMM
                bottomBracketDropMM
                bottomBracketHeightMM
              }
            }
          }
        `;
      })}
    }
    `;
};
export const searchProducts = /* GraphQL */ `
  query SearchProducts(
    $filter: SearchableProductFilterInput
    $sort: SearchableProductSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchProducts(filter: $filter, sort: $sort, limit: $limit, nextToken: $nextToken) {
      items {
        id
        recordType
        bikeSlug
        seoId
        qualityRating
        title
        link
        advertiser
        brand
        category
        locale
        price {
          amount
          amountWithoutDiscount
          decimalOffset
          currency
        }
        priceHistory {
          amount
          date
        }
        deliveryCost {
          amount
          decimalOffset
          currency
        }
        images {
          uri
          alt
        }
        imagesSmall {
          uri
          alt
        }
        renditionSmall
        renditionMedium
        renditionLarge
        renditionOpenGraph
        productCondition
        likes
        ean
      }
      nextToken
    }
  }
`;
export const getArticle = /* GraphQL */ `
  query GetArticle($id: ID!) {
    getArticle(id: $id) {
      id
      title
      short
      content
      images {
        id
        uri
        alt
        copyright
      }
      category
      locale
      author
      visibility
      copyright
      keywords
      updatedAt
      releaseTimestamp
      metadataTitle
      metadataDescription
    }
  }
`;
export const listArticles = /* GraphQL */ `
  query ListArticles($filter: ModelArticleFilterInput, $limit: Int, $nextToken: String) {
    listArticles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        short
        images {
          id
          uri
          alt
          copyright
        }
        category
        locale
        author
        visibility
        copyright
        keywords
        releaseTimestamp
        metadataTitle
        metadataDescription
      }
      nextToken
    }
  }
`;

export const articlesByUpdatedAt = /* GraphQL */ `
  query ArticlesByUpdatedAt(
    $visibility: String
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelArticleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    articlesByUpdatedAt(
      visibility: $visibility
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        short
        images {
          id
          uri
          alt
          copyright
        }
        content
        category
        locale
        author
        visibility
        copyright
        keywords
        updatedAt
        metadataTitle
        metadataDescription
      }
      nextToken
    }
  }
`;

export const queryArticles = /* GraphQL */ `
  query QueryArticles(
    $action: String!
    $searchString: String
    $categories: String
    $idFilter: String
    $excludeIds: String
    $sort: String
    $visibility: String
    $offset: Int
    $size: Int
    $locale: String
    $region: String
  ) {
    queryArticles(
      action: $action
      searchString: $searchString
      categories: $categories
      idFilter: $idFilter
      excludeIds: $excludeIds
      sort: $sort
      visibility: $visibility
      offset: $offset
      size: $size
      locale: $locale
      region: $region
    ) {
      items {
        id
        title
        short
        images {
          id
          uri
          alt
          copyright
        }
        category
        locale
        author
        visibility
        copyright
        keywords
        updatedAt
        metadataTitle
        metadataDescription
        releaseTimestamp
      }
      total
      offset
      size
    }
  }
`;

export const getAdvertiserCategory = /* GraphQL */ `
  query GetAdvertiserCategory($id: ID!) {
    getAdvertiserCategory(id: $id) {
      id
      advertiser
      advertiserCategory
      actualCategory
      assignedCategory
    }
  }
`;
export const listAdvertiserCategorys = /* GraphQL */ `
  query ListAdvertiserCategorys($limit: Int, $nextToken: String) {
    listAdvertiserCategorys(limit: $limit, nextToken: $nextToken) {
      items {
        id
        advertiser
        advertiserCategory
        actualCategory
        assignedCategory
      }
      nextToken
    }
  }
`;
export const getCategoryInfo = /* GraphQL */ `
  query GetCategoryInfo($id: ID!) {
    getCategoryInfo(id: $id) {
      id
      name
      count
      brands
    }
  }
`;
export const listCategoryInfos = /* GraphQL */ `
  query ListCategoryInfos($filter: ModelCategoryInfoFilterInput, $limit: Int, $nextToken: String) {
    listCategoryInfos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        count
        brands
      }
      nextToken
    }
  }
`;
export const getText = /* GraphQL */ `
  query GetText($id: ID!) {
    getText(id: $id) {
      id
      content
    }
  }
`;
export const createTextByIdBatchQuery = (ids) => {
  return /* GraphQL */ `
    query {
      ${ids.map((textId, i) => {
        return /* GraphQL */ `
          text${i}: getText(
            id: "${textId}"
          ) {
            id
            content
          }
        `;
      })}
    }
    `;
};

export const createPrefetchFeedAndTextQuery = (textIds) => {
  return /* GraphQL */ `
    query PrefetchFeedAndTextQuery(
      $action: String!
      $searchString: String
      $category: String
      $categories: String
      $categoryFilter: String
      $idFilter: String
      $excludeIds: String
      $brands: String
      $sort: String
      $priceMin: Int
      $priceMax: Int
      $visibility: String
      $advertiser: String
      $advertiserCategory: String
      $productCondition: String
      $sale: String
      $ean: String
      $seoId: String
      $offset: Int
      $size: Int
      $locale: String
      $region: String
      $recordTypes: String
    ) {
      ${textIds.map((textId, i) => {
        return /* GraphQL */ `
          text${i}: getText(
            id: "${textId}"
          ) {
            id
            content
          }
        `;
      })}
      queryProducts(
        action: $action
        searchString: $searchString
        category: $category
        categories: $categories
        categoryFilter: $categoryFilter
        idFilter: $idFilter
        excludeIds: $excludeIds
        brands: $brands
        sort: $sort
        priceMin: $priceMin
        priceMax: $priceMax
        visibility: $visibility
        advertiser: $advertiser
        advertiserCategory: $advertiserCategory
        productCondition: $productCondition
        sale: $sale
        ean: $ean
        seoId: $seoId
        offset: $offset
        size: $size
        locale: $locale
        region: $region
        recordTypes: $recordTypes
      ) {
        items {
          id
          qualityRating
          recordType
          bikeSlug
          seoId
          title
          link
          advertiser
          brand
          price {
            amount
            amountWithoutDiscount
            decimalOffset
            currency
          }
          priceHistory {
            amount
            date
          }
          description
          descriptionNormalized
          specificationGroups {
            key
            value {
              key
              value
            }
          }
          images {
            id
            uri
            alt
            copyright
          }
          imagesSmall {
            uri
            alt
            copyright
          }
          renditionSmall
          renditionMedium
          renditionLarge
          renditionOpenGraph
          productCondition
          category
          locale
          visibility
          deliveryCost {
            amount
            amountWithoutDiscount
            decimalOffset
            currency
          }
          color
          inStock
          inSale
          ean
          gender
          size
          likes
          bikeDetail {
            barTape
            bottomBracket
            brakeLever
            brakesDiscDiameter
            brakesFront
            brakesRear
            brakeSystem
            brakeBackPedal
            cassette
            chain
            crankLenght
            crankset
            detailDescription
            fork
            forkMaterial
            frame
            frameMaterial
            frameType
            frontDerailleurs
            gearCount
            gearShift
            handleBar
            headset
            hubFront
            hubRear
            lightFront
            lightRear
            modelYear
            mudGuard
            parkingSupport
            pedals
            rearDerailleurs
            rims
            reachMM
            saddle
            seatPost
            shifters
            shiftType
            spacer
            spokes
            stem
            suspensionTravelFront
            suspensionTravelRear
            tiresFront
            tiresRear
            weight
            maxWeight
            wheelset
            wheelSize
            assistanceLevel
            batteryCapacity
            batteryPack
            batteryPosition
            charger
            dynamo
            displayType
            drivePosition
            engine
            engineManufacturer
            enginePosition
            motorTorque
            batteryLoadingTime
            batteryIsRemovable
            speed
            tractionHelp
            stackReachRatio
            frontCenterMM
            wheelbaseMM
            rakeMM
            trailMM
            stackMM
            reachMM
            topTubeLengthMM
            seatTubeAngle
            seatTubeLengthMM
            headTubeAngle
            headTubeLengthMM
            chainstayLengthMM
            bottomBracketDropMM
            bottomBracketHeightMM
          }
        }
        brands {
          key
          count
        }
        categories {
          key
          count
        }
        inaccuracy
        total
        offset
        size
      }
    }
    `;
};

export const searchBarQuery = /* GraphQL */ `
  query SearchBarQuery($sizeProducts: Int, $sizeArticles: Int, $searchString: String) {
    searchRecommendation(searchString: $searchString) {
      recommendedTermsProducts
      recommendedTermsArticles
    }
    queryArticles(action: "search", size: $sizeArticles, visibility: "public", searchString: $searchString) {
      items {
        id
        title
        short
        images {
          id
          uri
          alt
          copyright
        }
        category
        locale
        author
        visibility
        copyright
        keywords
        updatedAt
        metadataTitle
        metadataDescription
        releaseTimestamp
      }
      total
      offset
      size
    }
    queryProducts(action: "search", visibility: "public", size: $sizeProducts, searchString: $searchString) {
      items {
        id
        recordType
        bikeSlug
        seoId
        title
        link
        advertiser
        brand
        price {
          amount
          amountWithoutDiscount
          decimalOffset
          currency
        }
        priceHistory {
          amount
          date
        }
        description
        descriptionNormalized
        specificationGroups {
          key
          value {
            key
            value
          }
        }
        images {
          id
          uri
          alt
          copyright
        }
        imagesSmall {
          uri
          alt
          copyright
        }
        renditionSmall
        renditionMedium
        renditionLarge
        renditionOpenGraph
        productCondition
        category
        locale
        visibility
        deliveryCost {
          amount
          amountWithoutDiscount
          decimalOffset
          currency
        }
        color
        inStock
        inSale
        ean
        gender
        size
        likes
      }
      brands {
        key
        count
      }
      categories {
        key
        count
      }
      inaccuracy
      total
      offset
      size
    }
  }
`;
