const prodBuild = process.env.NODE_ENV === "production";

const ENV_NAME = process.env.NEXT_PUBLIC_ENV_NAME;
const API_ENDPOINT = process.env.NEXT_PUBLIC_API_ENDPOINT;
const API_KEY = process.env.NEXT_PUBLIC_API_KEY;
const API_REGION = process.env.NEXT_PUBLIC_API_REGION;
const COGNITO_IDENTITY_POOL_ID = process.env.NEXT_PUBLIC_COGNITO_IDENTITY_POOL_ID;
const COGNITO_REGION = process.env.NEXT_PUBLIC_COGNITO_REGION;
const COGNITO_USERPOOL_ID = process.env.NEXT_PUBLIC_COGNITO_USERPOOL_ID;
const COGNITO_WEBCLIENT_ID = process.env.NEXT_PUBLIC_COGNITO_WEBCLIENT_ID;
const UPLOAD_BUCKET = process.env.NEXT_PUBLIC_UPLOAD_BUCKET;
const HOST_IMAGE = process.env.NEXT_PUBLIC_HOST_IMAGE;
const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL;
const LOCAL_DEV = process.env.NEXT_PUBLIC_LOCAL_DEV;

const defaultBackendConf = {
  aws_project_region: API_REGION || "eu-west-1",
  aws_cognito_identity_pool_id: COGNITO_IDENTITY_POOL_ID || "eu-west-1:67768d1d-4405-4081-bf14-3aafb27a699a",
  aws_cognito_region: COGNITO_REGION || "eu-west-1",
  aws_user_pools_id: COGNITO_USERPOOL_ID || "eu-west-1_FgvOPmZHY",
  aws_user_pools_web_client_id: COGNITO_WEBCLIENT_ID || "u0lp02go4pv6hsjv7qtdl31ag",
  oauth: {},
  aws_appsync_graphqlEndpoint:
    API_ENDPOINT || "https://q6p3fvajj5b7darbmdq5hkuhzm.appsync-api.eu-west-1.amazonaws.com/graphql",
  aws_appsync_region: API_REGION || "eu-west-1",
  aws_appsync_authenticationType: "API_KEY",
  aws_appsync_apiKey: API_KEY || "da2-eaxdzf53mbegvje7b4wsksucv4",
  aws_upload_bucket: UPLOAD_BUCKET || "production-backendstack-backe-imageupload6218b7c1-1lfa6d1ckyqrn",
  aws_image_host: HOST_IMAGE || "images.thecycleverse.com",
  base_url: BASE_URL || (prodBuild ? "https://thecycleverse.com" : ""),
};

const googleTestAdNetwork = "6355419";

const environments = {
  prod: {
    host: prodBuild ? "thecycleverse.com" : "localhost",
    pagetitle: prodBuild ? "The Cycleverse" : "The Cycleverse (localhost)",
    mailchimpPriceAlarmUrl:
      "//thecycleverse.us11.list-manage.com/subscribe/post?u=816994e5e66f284fcd1ad0c3d&amp;id=ccdd1544b8",
    mailchimpNewsletterUrl:
      "//thecycleverse.us11.list-manage.com/subscribe/post?u=816994e5e66f284fcd1ad0c3d&amp;id=ccdd1544b8",
    googleAnalyticsId: "UA-159595176-1",
    googleAnalyticsId4: "G-N28W4ECPJ3",
    googleAdSense: "ca-pub-7319643433847512",
    facebookPixelId: "2609229449400048",
    adNetworkId: "22983125063",
    name: "prod",
    backend: defaultBackendConf,
    products: {
      maximumPrice: 14000,
      minimumPrice: 0,
    },
    features: {
      disableAuthState: true,

      enableBikeAggregationOverview: false,
      enableBikeCompare: true,
      enableCategoryFilterOption: true,
      enableProductFAQs: true,

      prerenderArticleCategories: true,
      prerenderArticles: true,
      prerenderBrands: true,
      prerenderBestPrice: true,
      prerenderSalePage: true,

      productCategoryDescriptions: true,

      usePersonalizedProfile: false,

      showSpecificationGroups: false,
      showDescriptionNormalized: true,

      showAds: false,
      useMocksInAdSlots: false,

      useNewConsent: false,
    },
  },
  dev: {
    host: prodBuild ? "thecycleverse.com" : "localhost",
    pagetitle: prodBuild ? "The Cycleverse" : "The Cycleverse (localhost)",
    mailchimpPriceAlarmUrl:
      "//thecycleverse.us11.list-manage.com/subscribe/post?u=816994e5e66f284fcd1ad0c3d&amp;id=ccdd1544b8",
    mailchimpNewsletterUrl:
      "//thecycleverse.us11.list-manage.com/subscribe/post?u=816994e5e66f284fcd1ad0c3d&amp;id=ccdd1544b8",
    googleAnalyticsId: "UA-159595176-2",
    googleAnalyticsId4: "G-6QL4JFVNGC",
    googleAdSense: "ca-pub-7319643433847512",
    facebookPixelId: "2609229449400048",
    adNetworkId: !!LOCAL_DEV ? googleTestAdNetwork : "22983125063",
    name: "dev",
    backend: defaultBackendConf,
    products: {
      maximumPrice: 14000,
      minimumPrice: 0,
    },
    features: {
      disableAuthState: true,

      enableBikeAggregationOverview: true,
      enableBikeCompare: true,
      enableCategoryFilterOption: true,
      enableProductFAQs: true,

      prerenderArticleCategories: false,
      prerenderArticles: false,
      prerenderBrands: false,
      prerenderBestPrice: false,
      prerenderSalePage: false,

      productCategoryDescriptions: true,

      usePersonalizedProfile: true,

      showSpecificationGroups: true,
      showDescriptionNormalized: true,

      allowDeveloperMode: true,

      showAds: false,
      useMocksInAdSlots: !!LOCAL_DEV,

      useNewConsent: false,
    },
  },
};

const environment = environments[ENV_NAME] ? environments[ENV_NAME] : environments.dev;

export default environment;
