
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import React, { useState } from "react";
import { createClient, createFetcher } from "src/lib/fetcher";

import AdProvider from "src/components/Ads/AdProvider";
import Analytics from "src/lib/analytics/analytics";
import AuthContext from "src/components/Auth/AuthContext";
import { AuthStorage } from "src/lib/authStorage";
import { BikeCompareStorage } from "src/components/BikeCompare/BikeCompareStorage";
import { ConsentStorage } from "src/components/CookieConsent/ConsentStorage";
import CssBaseline from "@material-ui/core/CssBaseline";
import GqlClientContext from "src/lib/gqlClientContext";
import HeaderHighlightContext from "src/components/Layout/HeaderHighlightContext";
import Router from "next/router";
import { SWRConfig } from "swr";
import SeoHead from "src/components/Seo/SeoHead";
import { TextGobalKeys } from "types";
import { ThemeProvider } from "@material-ui/core/styles";
import TrafficSource from "src/lib/analytics/trafficSource";
import { addToHistory } from "src/lib/browseHistory";
import environment from "src/environment";
import { getText } from "src/graphql/queries";
import theme from "src/theme";
import { useEffectOnce } from "react-use";
import useTranslation from "next-translate/useTranslation";

function handleConsentCookies(cookies) {
  if (cookies) {
    // use cookies from request for SSR
    ConsentStorage.from(cookies);
  } else {
    // clear cookies
    ConsentStorage.reset();
  }
}

function handleBikeCompareCookies(cookies) {
  if (cookies) {
    // use cookies from request for SSR
    BikeCompareStorage.from(cookies);
  } else {
    // clear cookies
    BikeCompareStorage.reset();
  }
}

const getUserId = async () => {
  const authStorageUser = AuthStorage.getUserId();
  if (authStorageUser) {
    return authStorageUser;
  } else {
    const newId = AuthStorage.newUserId();
    AuthStorage.setUserId(newId);
    return newId;
  }
};

const MyApp = (props) => {
  const { Component, headerHighlight, pageProps, router } = props;
  const { lang: locale } = useTranslation();
  const [gqlFetcher, setGqlFetcher] = useState(() => createFetcher());
  const [auth, setAuth] = useState({
    groups: [],
    isAdmin: false,
  });
  const url = `/${locale}${router.asPath}`;
  const [lastPageViewLogged, setLastPageViewLogged] = useState<string>(url);

  useEffectOnce(() => {
    const fn = async () => {
      // Remove the server-side injected CSS.
      const jssStyles = document.querySelector("#jss-server-side");
      if (jssStyles) {
        jssStyles.parentElement.removeChild(jssStyles);
      }

      const userId = await getUserId();
      const accessToken = await AuthStorage.getAccessToken();
      let stopRefresh;
      if (accessToken) {
        const client = createClient(accessToken);
        const gqlFetcher = createFetcher(client);
        setGqlFetcher(() => gqlFetcher);
        const userGroups = await AuthStorage.getUserGroups();
        setAuth({
          groups: userGroups,
          isAdmin: userGroups.includes("Admin"),
        });
        stopRefresh = AuthStorage.periodicRefreshAccessToken();
      }
      await Analytics.init(userId);

      // and check window for clientside
      if (typeof window !== "undefined") {
        setLastPageViewLogged(url);
        Analytics.pageView(url, document?.title);
        TrafficSource.updateLandingpage(url);
        Router.events.on("routeChangeComplete", (url: string) => {
          const urlWithLocale = !url.startsWith(`/${locale}`) && url.startsWith(`/`) ? `/${locale}${url}` : url;
          if (urlWithLocale != lastPageViewLogged) {
            setLastPageViewLogged(urlWithLocale);
            Analytics.pageView(urlWithLocale, document?.title);
          }
          window && window.scrollTo(0, 0);
        });
        Router.events.on("beforeHistoryChange", (url) => {
          addToHistory(url);
        });
      }

      return stopRefresh || (() => {});
    };
    fn();
  });

  return (
    <React.Fragment>
      <SeoHead
        canonical={`https://thecycleverse.com${
          pageProps.canonicalPath ? pageProps.canonicalPath : `/${locale}${router.asPath != "/" ? router.asPath : ""}`
        }`}
      >
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
      </SeoHead>

      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <HeaderHighlightContext.Provider value={headerHighlight}>
          <AuthContext.Provider value={{ auth, setAuth }}>
            <GqlClientContext.Provider value={gqlFetcher}>
              <SWRConfig
                value={{
                  // refreshInterval: 3000,
                  fetcher: gqlFetcher,
                }}
              >
                <AdProvider>
                  <Component {...pageProps} />
                </AdProvider>
              </SWRConfig>
            </GqlClientContext.Provider>
          </AuthContext.Provider>
        </HeaderHighlightContext.Provider>
      </ThemeProvider>
    </React.Fragment>
  );
};

MyApp.getInitialProps = async ({ Component, ctx }) => {
  const cookies = ctx && ctx.req && ctx.req.headers ? ctx.req.headers.cookie : undefined;
  handleConsentCookies(cookies);
  handleBikeCompareCookies(cookies);

  const fetcher = createFetcher();
  const id: TextGobalKeys = "header-highlight";
  const headerHighlightData = await fetcher([
    getText,
    JSON.stringify({
      id,
    }),
  ]);

  return {
    headerHighlight: headerHighlightData?.getText?.content,
    pageProps: {
      ...(Component.getInitialProps ? await Component.getInitialProps(ctx) : {}),
    },
  };
};

const __Page_Next_Translate__ = MyApp;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  