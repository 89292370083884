import { sessionStorageSet, sessionStorageGet } from "./storage";

const storageKey = "browseHistory";

export const addToHistory = (url: string) => {
  const history = sessionStorageGet(storageKey, []);
  sessionStorageSet(storageKey, [url, ...history]);
};

export const getHistory = (): string[] => {
  return sessionStorageGet(storageKey, []);
};
